import axios from 'axios'
import { get } from 'lodash'

class Http {
  req
  constructor() {
    const token = ''

    this.req = axios.create({
      withCredentials: true,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
      params: {
        lang: localStorage.language
      }
    })

    this.req.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        if (401 === get(error, 'response.status')) {
          window.location.assign('/login')
            // handle error: inform user, go to login, etc
        } else {
            return Promise.reject(error);
        }
    });
  }

  setUser(token) {
    this.req.defaults.headers.Authorization = 'Bearer ' + token
  }

  removeToken() {
    this.req.defaults.headers.Authorization = null
  }
}

const http = new Http
const req = http.req
export default req
export { req, http }
