import en from './en.json'
import fr from './fr.json'
import it from './it.json'
import es from './es.json'
import por from './por.json'
import ge from './ge.json'
import chs from './chs.json'
import cht from './cht.json'

import ar from './ar.json'
import est from './est.json'
import hu from './hu.json'
import lv from './lv.json'
import ro from './ro.json'
import ru from './ru.json'
import sw from './sw.json'
import tu from './tu.json'

export const languages = {
    en: en,
    fr: fr,
    it: it,
    es: es,
    por: por,
    ge: ge,
    chs: chs,
    cht: cht,
    ar: ar,
    est: est,
    hu: hu,
    ro: ro,
    ru: ru,
    sw: sw,
    tu: tu
}
